<template>
  <OfflineCamerasReport />
</template>

<script>
import OfflineCamerasReport from "@/components/offlineCameras/OfflineCamerasReport"

export default {
  components: { OfflineCamerasReport },
  head() {
    return {
      title: "Admin - Offline Cameras",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
}
</script>
